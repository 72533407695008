import { Tooltip, Select } from "antd";
import { FC, useContext } from "react";
import { MainContext } from "../context/Main";

const { Option } = Select;

export const statusSelectOptions = [
  <Option value="Unfinished" key="Unfinished">
    Unfinished
  </Option>,
  <Option value="Pending review" key="Pending review">
    Pending review
  </Option>,
  <Option value="Failed" key="Failed">
    Failed
  </Option>,
  <Option value="Qualified" key="Qualified">
    Qualified
  </Option>
];

export const QuestionAnswerGridRenderer: FC = ({
  value,
  column,
  data
}: any) => {
  if (typeof value !== "string") return value;
  const groupNumber = parseInt(column?.colId.match(/\d+/g));
  const placement =
    !isNaN(groupNumber) && data?.questionCount - groupNumber < 3
      ? "left"
      : null;

  return (
    <Tooltip title={value} overlayInnerStyle={placement ? {} : { width: 400 }}>
      **
    </Tooltip>
  );
};

export const StatusRenderer: FC = ({ value, data: { resultId } }: any) => {
  const { setResultStatus } = useContext(MainContext);
  return (
    <Select
      className="grid-status-selector"
      value={value}
      onChange={(newStatus: string) => setResultStatus(resultId, newStatus)}
    >
      {statusSelectOptions}
    </Select>
  );
};
