import { useEffect, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MainContext } from "../context/Main";
import { Input, Button, Popconfirm } from "antd";

const { TextArea } = Input;

export const TestUnit = () => {
  const params = useParams();
  const {
    getTest,
    currentQuestion,
    introduction,
    saveAnswer,
    questionsCounter,
    questionIndex,
    isLoggedIn
  } = useContext(MainContext);
  const navigate = useNavigate();
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    const id = params?.id;
    if (id && isLoggedIn) {
      getTest(id);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (currentQuestion && !answer) {
      const backupAnswer = localStorage.getItem(
        `backupAnswer${currentQuestion && currentQuestion.id}`
      );
      if (backupAnswer) setAnswer(backupAnswer);
    }
  }, [currentQuestion]);

  const handleChange = (e: any) => {
    setAnswer(e.currentTarget.value);
    localStorage.setItem(
      `backupAnswer${currentQuestion && currentQuestion.id}`,
      answer
    );
  };

  const nextQuestion = () => {
    localStorage.removeItem(
      `backupAnswer${currentQuestion && currentQuestion.id}`
    );
    saveAnswer(answer, navigate, params?.id);
    setAnswer("");
    setImageValid(false);
  };

  const [imageValid, setImageValid] = useState(() => false);
  useEffect(() => {
    const image: any = document.createElement("img");
    image.src = currentQuestion && currentQuestion.ImagesBase64;
    image.onload = () => setImageValid(() => true);
    image.onerror = () => setImageValid(() => false);
    image.remove();
  }, [currentQuestion]);

  return (
    <div className="w-full p-4 flex flex-col overflow-y-auto relative app-body">
      {introduction && (
        <div className="w-full px-3 py-1 bg-green-300 justify-center flex items-center font-semibold text-lg">
          {introduction}
        </div>
      )}
      {currentQuestion && (
        <div className="w-full px-3 py-1 bg-gray-300">
          {currentQuestion.ImagesBase64 ? (
            <div className="w-full flex justify-center">
              {imageValid && (
                <img
                  src={currentQuestion.ImagesBase64}
                  alt=""
                  className="max-h-96"
                  style={{ borderRadius: 10 }}
                />
              )}
            </div>
          ) : null}
          <div className="font-semibold py-1 text-center text-xl">
            {currentQuestion.body}
          </div>

          <div className="w-full my-3">
            <TextArea
              value={answer}
              onChange={handleChange}
              allowClear
              rows={4}
              autoFocus
            />
          </div>
          <div className="w-full flex items-center justify-between">
            <div className="">
              Question #{`${questionIndex + 1} / ${questionsCounter}`}
            </div>
            <Popconfirm
              onConfirm={nextQuestion}
              title="Are you sure? Once submitted - you can't go back"
            >
              <Button type="primary" className="my-1">
                Next Question
              </Button>
            </Popconfirm>
          </div>
        </div>
      )}
    </div>
  );
};
