export const SET_IS_LOGGED_IN = "auth/SET_IS_LOGGED_IN";
export const SET_TOKEN_MODAL = "auth/SET_TOKEN_MODAL";
export const SET_IS_ADMIN = "auth/SET_IS_ADMIN";
export const SET_LOADING = "main/SET_LOADING";
export const SET_RESULTS = "main/SET_RESULTS";
export const SET_ERROR = "main/SET_ERROR";
export const SET_TEST_LIST = "tests/SET_TEST_LIST";
export const SET_QUESTION_LIST = "tests/SET_QUESTION_LIST";
export const CLEAN_EDIT_SCREEN = "tests/CLEAN_EDIT_SCREEN";
export const SET_TEST_CONTENT = "testing/SET_TEST_CONTENT";
export const SET_CURRENT_QUESTION = "testing/SET_CURRENT_QUESTION";
export const FINISH_TEST = "testing/FINISH_TEST";
