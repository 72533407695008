import { createContext, useReducer, ReactNode } from "react";
import {
  client,
  adminClient,
  GET_TEST_LIST,
  CREATE_NEW_TEST,
  GET_TEST_BY_ID,
  GET_QUESTIONS_BY_TEST_ID,
  CREATE_NEW_QUESTION,
  DELETE_QUESTION,
  EDIT_QUESTION,
  EDIT_TEST,
  GET_QUESTIONS_IDS_BY_TEST_ID,
  GET_CANDIDATE,
  CREATE_CANDIDATE,
  GET_RESULT_BY_TEST_ID,
  CREATE_NEW_RESULT,
  GET_ANSWERS_BY_RESULT_ID,
  GET_QUESTION_BY_ID,
  CREATE_NEW_ANSWER,
  GET_RESULTS_LIST,
  UPDATE_ANSWERS_RATING,
  DELETE_ANSWERS_BY_TEST_ID,
  SET_RESULT_STATUS,
  SET_RESULT_STATUS_ADMIN,
  TOGGLE_LISTED,
  UPDATE_NOTES
} from "./graphqlStuff";
import { notification } from "antd";
import {
  ITest,
  IState,
  IQuestion,
  ISetQuestionPayload,
  ITestContent
} from "./interfaces";
import { appReducer } from "./appReducer";
import { fetchResults } from "./helpers";
import { logout as magicLinkLogout } from "../services/login";
import * as t from "./actionTypes";

export const MainContext = createContext<any>({});

const standardErrorNotification = {
  message: "Something went wrong",
  description: "Please, try again or contact the administrator"
};

const initialState: IState = {
  isLoggedIn: false,
  isAdmin: false,
  tokenModal: true,
  loading: false,
  testList: [],
  questionList: [],
  introduction: "",
  error: null
};

export const MainState = ({ children }: { children: ReactNode }) => {
  const [state, dispatch]: [any, any] = useReducer(appReducer, initialState);
  const {
    isLoggedIn,
    tokenModal,
    loading,
    isAdmin,
    testList,
    introduction,
    questionList,
    currentQuestion,
    resultTableData,
    questionsCounter,
    questionIndex,
    error
  } = state;

  const checkToken = () => {
    const user = localStorage.getItem("user");
    try {
      const token = user ? JSON.parse(user)?.did : null;

      if (token) {
        setIsLoggedIn(true);
        const expiry = user ? JSON.parse(user)?.expiry : null;
        if (Number(expiry) < Date.now()) {
          logout();
          return;
        }
        login(token);
      }
    } catch (error) {
      logout();
    }
  };

  const setIsLoggedIn = (payload: boolean) => {
    dispatch({
      type: t.SET_IS_LOGGED_IN,
      payload
    });
  };

  const setLoading = (payload: boolean) => {
    dispatch({
      type: t.SET_LOADING,
      payload
    });
  };

  const setTestList = (payload: ITest[]) => {
    dispatch({
      type: t.SET_TEST_LIST,
      payload
    });
  };

  const setQuestionsList = (payload: ISetQuestionPayload) => {
    dispatch({
      type: t.SET_QUESTION_LIST,
      payload
    });
  };

  const setIsAdmin = (payload: boolean) => {
    dispatch({
      type: t.SET_IS_ADMIN,
      payload
    });
  };

  const cleanEditScreen = () => {
    dispatch({
      type: t.CLEAN_EDIT_SCREEN
    });
  };

  const setTestContent = (payload: ITestContent) => {
    dispatch({
      type: t.SET_TEST_CONTENT,
      payload
    });
  };

  const setCurrentQuestion = (payload: IQuestion) => {
    dispatch({
      type: t.SET_CURRENT_QUESTION,
      payload
    });
  };

  const finishTest = (testId: string) => {
    dispatch({
      type: t.FINISH_TEST,
      payload: testId
    });
  };

  const setResults = (results: any[]) => {
    dispatch({
      type: t.SET_RESULTS,
      payload: fetchResults(results)
    });
  };

  const updateResults = (results: any) => {
    dispatch({
      type: t.SET_RESULTS,
      payload: results
    });
  };

  const setError = (error: string) => {
    dispatch({
      type: t.SET_ERROR,
      payload: error
    });
  };

  const errorFlow = (errorText: string) => {
    console.error(errorText);
    setError(errorText);
  };

  const login = async (token: string) => {
    const user = localStorage.getItem("user");

    try {
      const storedToken = user ? JSON.parse(user)?.did : null;

      if (!storedToken) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            did: token,
            expiry: new Date().getTime() + 1000 * 7200 * 3
          })
        );
      } else {
        const expiry = user ? JSON.parse(user)?.expiry : null;
        if (Number(expiry) < Date.now()) {
          logout();
          return;
        }
      }
      setLoading(true);
      await checkIfAdmin(token);
      const testListResponse = await client.query({
        query: GET_TEST_LIST,
        fetchPolicy: "network-only",
        variables: { userId: token }
      });

      if (testListResponse && testListResponse.data) {
        const candidateResponse = await client.query({
          query: GET_CANDIDATE,
          variables: { id: token },
          fetchPolicy: "network-only"
        });
        const id = candidateResponse?.data?.Candidate_by_pk?.id;
        if (!id) {
          const createCandidateResponse = await client.mutate({
            mutation: CREATE_CANDIDATE,
            variables: { id: token }
          });
          const answer =
            createCandidateResponse?.data?.insert_Candidate?.returning?.[0]?.id;
          if (answer) {
            setTestList(testListResponse.data.Test);
          } else {
            notification.error(standardErrorNotification);
            errorFlow("login ERROR: Create Candidate: No Response");
          }
        } else {
          setTestList(testListResponse.data.Test);
        }
      } else {
        setIsLoggedIn(false);
      }
    } catch (e: any) {
      errorFlow(`login catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
      setIsLoggedIn(false);
    }
  };

  const checkIfAdmin = async (token: string) => {
    setLoading(true);
    try {
      const candidateResponse = await adminClient.query({
        query: GET_CANDIDATE,
        variables: { id: token },
        fetchPolicy: "network-only"
      });

      if (candidateResponse && candidateResponse.data) {
        if (!isAdmin) setIsAdmin(true);
        else setLoading(false);
        return true;
      } else {
        if (isAdmin) setIsAdmin(false);
        else setLoading(false);
        return false;
      }
    } catch (e) {
      if (isAdmin) setIsAdmin(false);
      else setLoading(false);
      return false;
    }
  };

  const createTest = async (introduction: string) => {
    setLoading(true);
    try {
      const response = await adminClient.mutate({
        mutation: CREATE_NEW_TEST,
        variables: { introduction }
      });

      const newTest = response.data?.insert_Test?.returning?.[0];
      if (newTest) {
        setTestList([...testList, newTest]);
        notification.success({
          message: `Test "${introduction}" has been successfully created`
        });
      } else {
        errorFlow("createTest ERROR: new test is not created");
      }
    } catch (e: any) {
      errorFlow(`createTest catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const removeQuestion = async (id: string) => {
    setLoading(true);
    try {
      const response = await adminClient.mutate({
        mutation: DELETE_QUESTION,
        variables: { id }
      });

      const removedTest = response.data?.delete_Question?.returning?.[0];
      if (removedTest) {
        setQuestionsList({
          list: questionList.filter((el: IQuestion) => el.id !== removedTest.id)
        });
      } else {
        errorFlow("removeQuestion ERROR: removedTest is empty");
      }
    } catch (e: any) {
      errorFlow(`removeQuestion catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const getTestWithQuestionsByTestId = async (id: string) => {
    setLoading(true);
    try {
      const testResponse = await adminClient.query({
        query: GET_TEST_BY_ID,
        variables: { id },
        fetchPolicy: "network-only"
      });
      const questionsResponse = await adminClient.query({
        query: GET_QUESTIONS_BY_TEST_ID,
        variables: { id },
        fetchPolicy: "network-only"
      });

      if (
        testResponse &&
        testResponse.data &&
        questionsResponse &&
        questionsResponse.data
      ) {
        setQuestionsList({
          list: questionsResponse.data.Question,
          introduction: testResponse.data.Test?.[0]?.introduction
        });
      } else {
        errorFlow(
          `getTestWithQuestionsByTestId ERROR: test or questions request failed`
        );
      }
    } catch (e: any) {
      errorFlow(
        `getTestWithQuestionsByTestId catch ERROR: ${e.name}:${e.message}`
      );
      notification.error(standardErrorNotification);
    }
  };

  const createQuestion = async (question: IQuestion) => {
    setLoading(true);
    try {
      const response = await adminClient.mutate({
        mutation: CREATE_NEW_QUESTION,
        variables: { ...question }
      });

      const newQuestion = response.data?.insert_Question?.returning?.[0];
      if (newQuestion) {
        setQuestionsList({ list: [...questionList, newQuestion] });
      } else {
        errorFlow(`createQuestion ERROR: newQuestion is empty`);
      }
    } catch (e: any) {
      errorFlow(`createQuestion catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const removeTest = async (id: string) => {
    setLoading(true);
    try {
      const testDeleteResponse = await adminClient.mutate({
        mutation: DELETE_ANSWERS_BY_TEST_ID,
        variables: { id },
        fetchPolicy: "network-only"
      });
      if (testDeleteResponse.data) {
        const user = localStorage.getItem("user");
        const token = user ? JSON.parse(user)?.did : null;
        const testListResponse = await adminClient.query({
          query: GET_TEST_LIST,
          fetchPolicy: "network-only",
          variables: { userId: token }
        });
        setTestList(testListResponse?.data?.Test ?? []);
        const deletedTest =
          testDeleteResponse.data?.delete_Test_by_pk?.introduction ?? "";
        notification.success({
          message: `Test ${deletedTest} has been successfully removed`
        });
      } else {
        errorFlow(`removeTest ERROR: testDeleteResponse is empty`);
      }
    } catch (e: any) {
      errorFlow(`removeTest catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const editQuestion = async (question: IQuestion) => {
    setLoading(true);
    try {
      const { id, body, ImagesBase64 } = question;
      const response = await adminClient.mutate({
        mutation: EDIT_QUESTION,
        variables: { id, body, ImagesBase64 },
        fetchPolicy: "network-only"
      });

      const updatedQuestion = response.data?.update_Question?.returning?.[0];
      if (updatedQuestion) {
        setQuestionsList({
          list: questionList.map((el: IQuestion) =>
            el.id !== updatedQuestion.id ? el : updatedQuestion
          )
        });
      } else {
        errorFlow(`updatedQuestion ERROR: updatedQuestion is empty`);
      }
    } catch (e: any) {
      errorFlow(`editQuestion catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const editTest = async (test: ITest) => {
    setLoading(true);
    try {
      const user = localStorage.getItem("user");
      const token = user ? JSON.parse(user)?.did : null;
      const { id, introduction } = test;
      const response = await adminClient.mutate({
        mutation: EDIT_TEST,
        variables: { id, introduction, userId: token },
        fetchPolicy: "network-only"
      });

      const updatedTest = response.data?.update_Test?.returning?.[0];
      if (updatedTest) {
        setTestList(
          testList.map((el: ITest) =>
            el.id !== updatedTest.id ? el : updatedTest
          )
        );
        setQuestionsList({
          list: questionList,
          introduction: updatedTest.introduction
        });
      } else {
        errorFlow(`editTest ERROR: updatedTest is empty`);
      }
    } catch (e: any) {
      errorFlow(`editTest catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const getTest = async (id: string) => {
    setLoading(true);
    try {
      let status;
      let resultId;
      let questionIndex;

      const testResponse = await client.query({
        query: GET_TEST_BY_ID,
        variables: { id },
        fetchPolicy: "network-only"
      });

      const test = testResponse?.data?.Test?.[0];
      if (test && !test.listed && !isAdmin) {
        errorFlow("Test is not listed anymore");
        notification.warning({
          message: "Test is not listed anymore",
          description: "Please, contact the administrator"
        });
        return;
      }

      const questionsResponse = await client.query({
        query: GET_QUESTIONS_IDS_BY_TEST_ID,
        variables: { id },
        fetchPolicy: "network-only"
      });

      const questions = questionsResponse?.data?.Question?.map(
        (el: any) => el.id
      );

      const questionsCounter = questions && questions.length;

      if (test && questionsCounter) {
        const resultResponse = await client.query({
          query: GET_RESULT_BY_TEST_ID,
          variables: { id },
          fetchPolicy: "network-only"
        });

        const result = resultResponse?.data?.TestResult?.[0];
        const user = localStorage.getItem("user");
        const token = user ? JSON.parse(user)?.did : null;
        const expiry = user ? JSON.parse(user)?.expiry : null;
        if (Number(expiry) < Date.now()) {
          logout();
          return;
        }
        const userId = result ? result.userId : token;

        if (userId) {
          if (!result) {
            const newResultResponse = await client.mutate({
              mutation: CREATE_NEW_RESULT,
              variables: { testId: id, userId }
            });
            const newResult =
              newResultResponse?.data?.insert_TestResult?.returning?.[0];
            resultId = newResult.id;
            status = newResult.id;
            status = "Unfinished";
            questionIndex = 0;
          } else {
            resultId = result?.id;
            status = result?.status;
            const answersResponse = await client.query({
              query: GET_ANSWERS_BY_RESULT_ID,
              variables: { resultId },
              fetchPolicy: "network-only"
            });

            const answers = answersResponse?.data?.Answer;
            questionIndex = (answers && answers.length) || 0;

            if (answers.length === questionsCounter) {
              errorFlow(`TEST ${id} IS ALREADY TAKEN`);
              notification.warning({
                message: "Test is already taken",
                description: "Please, contact the administrator"
              });
              questionIndex = null;
              return;
            }
          }

          if (resultId && questions[questionIndex]) {
            const questionResponse = await client.query({
              query: GET_QUESTION_BY_ID,
              variables: { id: questions[questionIndex] },
              fetchPolicy: "network-only"
            });
            const currentQuestion = questionResponse?.data?.Question_by_pk;

            setTestContent({
              introduction: test.introduction,
              questions,
              resultId,
              userId,
              questionIndex,
              currentQuestion,
              questionsCounter,
              status
            });
          } else {
            errorFlow("NO QUESTIONS?");
            notification.info({
              message: "NO QUESTIONS",
              description: "Please, contact the administrator"
            });
          }
        } else {
          errorFlow("NO USER TOKEN");
          notification.error({
            message: "NO USER TOKEN",
            description: "Please, contact the administrator"
          });
        }
      } else if (!questions.length) {
        errorFlow(
          "This test is not ready yet. There are no questions here. Please, contact the administrator"
        );
        notification.error({
          message: "This test is not ready yet",
          description:
            "There are no questions here. Please, contact the administrator"
        });
      } else {
        errorFlow("getTest: test response is empty?");
        notification.error(standardErrorNotification);
      }
    } catch (e: any) {
      errorFlow(`getTest catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const getResults = async () => {
    setLoading(true);

    try {
      const resultsResponse = await adminClient.query({
        query: GET_RESULTS_LIST,
        fetchPolicy: "network-only"
      });
      const results = resultsResponse.data.TestResult;
      if (results) {
        setResults(results);
      } else {
        notification.error(standardErrorNotification);
        errorFlow("getResults: no results");
      }
    } catch (e: any) {
      errorFlow(`getResults catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const setResultStatus = async (resultId: string, status: string) => {
    setLoading(true);

    try {
      const updateResultResponse = await adminClient.mutate({
        mutation: SET_RESULT_STATUS_ADMIN,
        variables: { resultId, status },
        fetchPolicy: "network-only"
      });
      const updatedResult = updateResultResponse?.data?.update_TestResult_by_pk;
      if (updatedResult && resultTableData?.rowData?.length) {
        const newResultTableData = {
          ...resultTableData,
          rowData: resultTableData.rowData.map((el: any) =>
            el.resultId === updatedResult.id
              ? { ...el, status: updatedResult.status }
              : el
          )
        };
        updateResults(newResultTableData);
      }
      setLoading(false);
    } catch (e: any) {
      errorFlow(`setResultStatus catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const saveAnswer = async (body: string, navigate: any, testId: string) => {
    setLoading(true);

    try {
      const { resultId, userId, questions, questionIndex, status } = state;
      const createAnswerRequest = await client.mutate({
        mutation: CREATE_NEW_ANSWER,
        variables: { body, resultId, userId },
        fetchPolicy: "network-only"
      });
      if (createAnswerRequest?.data?.insert_Answer_one) {
        if (questions[questionIndex + 1]) {
          const newQuestionRequest = await client.query({
            query: GET_QUESTION_BY_ID,
            variables: { id: questions[questionIndex + 1] },
            fetchPolicy: "network-only"
          });
          const currentQuestion = newQuestionRequest?.data?.Question_by_pk;
          setCurrentQuestion(currentQuestion);
        } else {
          notification.info({
            message: "Your test is finished!",
            description: "Please, wait for results"
          });
          // Finalize the result (Set status "pendingReview")
          if (status !== "Pending review") {
            await client.mutate({
              mutation: SET_RESULT_STATUS,
              variables: { resultId, status: "Pending review" },
              fetchPolicy: "network-only"
            });
          }
          finishTest(testId);
          navigate("/");
        }
      } else {
        notification.error(standardErrorNotification);
        errorFlow("saveAnswer ERROR: new answer is not created");
      }
    } catch (e: any) {
      errorFlow(`saveAnswer catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const setAnswerRating = async (id: string, rating: string) => {
    setLoading(true);
    try {
      const createAnswerRequest = await adminClient.mutate({
        mutation: UPDATE_ANSWERS_RATING,
        variables: { id, rating },
        fetchPolicy: "network-only"
      });
      if (createAnswerRequest?.data?.update_Answer_by_pk?.id) {
        setLoading(false);
      } else {
        notification.error(standardErrorNotification);
        errorFlow(`setAnswerRating response is empty!`);
      }
    } catch (e: any) {
      errorFlow(`setAnswerRating catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  // const setResultStatus = async (resultId: string, status: string) => {
  //   setLoading(true);

  //   try {
  //     const updateResultResponse = await adminClient.mutate({
  //       mutation: SET_RESULT_STATUS,
  //       variables: { resultId, status },
  //       fetchPolicy: "network-only"
  //     });
  //     const updatedResult = updateResultResponse?.data?.update_TestResult_by_pk;
  //     if (updatedResult && resultTableData?.rowData?.length) {
  //       const newResultTableData = {
  //         ...resultTableData,
  //         rowData: resultTableData.rowData.map((el: any) =>
  //           el.resultId === updatedResult.id
  //             ? { ...el, status: updatedResult.status }
  //             : el
  //         )
  //       };
  //       updateResults(newResultTableData);
  //     }
  //     setLoading(false);
  //   } catch (e: any) {
  //     errorFlow(`setResultStatus catch ERROR: ${e.name}:${e.message}`);
  //     notification.error(standardErrorNotification);
  //   }
  // };

  const updateNotes = async (resultId: string, notes: string) => {
    setLoading(true);
    try {
      const updateNotesResponse = await adminClient.mutate({
        mutation: UPDATE_NOTES,
        variables: { resultId, notes },
        fetchPolicy: "network-only"
      });
      const updatedNotes = updateNotesResponse?.data?.update_TestResult_by_pk;
      if (updatedNotes && resultTableData?.rowData?.length) {
        const newResultTableData = {
          ...resultTableData,
          rowData: resultTableData.rowData.map((el: any) =>
            el.resultId === updatedNotes.id
              ? { ...el, status: updatedNotes.status }
              : el
          )
        };
        updateResults(newResultTableData);
      }
      setLoading(false);
    } catch (e: any) {
      errorFlow(`updateNotes ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const logout = async () => {
    setLoading(true);

    try {
      localStorage.removeItem("user");
      setIsLoggedIn(false);
      magicLinkLogout();
      setLoading(false);
    } catch (e: any) {
      errorFlow(`logout catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  const toggleListed = async (listed: boolean, testId: string) => {
    setLoading(true);
    try {
      const toggleListedResponse = await adminClient.mutate({
        mutation: TOGGLE_LISTED,
        variables: { listed, testId },
        fetchPolicy: "network-only"
      });
      if (toggleListedResponse?.data?.update_Test_by_pk) {
        setTestList(
          testList.map((el: ITest) =>
            el.id === testId ? { ...el, listed } : el
          )
        );
      } else {
        notification.error(standardErrorNotification);
        errorFlow(`toggleListed response is empty!`);
      }
    } catch (e: any) {
      errorFlow(`toggleListed catch ERROR: ${e.name}:${e.message}`);
      notification.error(standardErrorNotification);
    }
  };

  return (
    <MainContext.Provider
      value={{
        isLoggedIn,
        isAdmin,
        checkToken,
        tokenModal,
        login,
        loading,
        testList,
        // enterAsAdmin,
        createTest,
        removeTest,
        getTestWithQuestionsByTestId,
        introduction,
        questionList,
        cleanEditScreen,
        createQuestion,
        removeQuestion,
        editQuestion,
        editTest,
        getTest,
        saveAnswer,
        currentQuestion,
        resultTableData,
        getResults,
        setAnswerRating,
        logout,
        questionsCounter,
        questionIndex,
        setResultStatus,
        toggleListed,
        error,
        updateNotes
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
