import { Modal, Input, Button } from "antd";
import { useState, useContext, FC, FormEvent } from "react";
import { MainContext } from "../context/Main";
import login from "../services/login";

export const LoginModal: FC = () => {
  const [email, setEmail] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const { login: appLogin } = useContext(MainContext);
  const [tokenMode, setTokenMode] = useState(false);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const handleOk = async () => {
    setLoginLoading(true);
    if (email) {
      if (tokenMode) {
        appLogin(email);
        setLoginLoading(false);
      } else {
        await login(email, appLogin, setLoginLoading);
      }
    }
  };

  const switchTokenMode = () => {
    setTokenMode(prev => !prev);
  };

  return (
    <Modal
      title={
        <div className="login-modal-img w-full flex justify-center mb-2 font-semibold flex-col items-center">
          Welcome dear thinker!
          <div className="login-img" />
        </div>
      }
      // title={tokenMode ? "Enter token" : "Enter your email"}
      open
      onOk={handleOk}
      closable={false}
      footer={[
        <span
          className="float-left cursor-pointer hover:text-blue-500 leading-7"
          key="link1"
          onClick={() => switchTokenMode()}
        >
          {tokenMode ? "Enter Email" : "I already have token"}
        </span>,
        <Button
          type="primary"
          onClick={handleOk}
          disabled={!email}
          key="okButton"
          loading={loginLoading}
        >
          OK
        </Button>
      ]}
    >
      <>
        <div className="login-modal-img w-full flex justify-center mb-2 font-semibold flex-col items-center">
          {tokenMode ? "Enter token" : "Enter your email"}
        </div>
        <Input onChange={handleChange} value={email} onPressEnter={handleOk} />
      </>
    </Modal>
  );
};
