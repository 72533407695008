import { useContext, useState, useEffect } from "react";
import { MainContext } from "../context/Main";
import { Button } from "antd";
import { NewTestModal } from "../components/NewTestModal";
import { useNavigate } from "react-router-dom";
import { ITest } from "../context/interfaces";
import { PlusButton } from "../components/PlusButton";
import { testStatusBackgrounds } from "../context/constants";
import { RemoveTestWarning } from "../components/RemoveTestWarning";
import { ListedToggleSwitch } from "../components/ListedToggleSwitch";

export const TestList = () => {
  const {
    testList,
    isAdmin,
    createTest,
    removeTest,
    introduction,
    cleanEditScreen,
    tokenModal,
    toggleListed,
    loading
  } = useContext(MainContext);
  const [newTestModal, setNewTestModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (introduction) cleanEditScreen();
  }, []);

  const addNewTest = () => {
    setNewTestModal(true);
  };

  const closeNewTestModal = () => {
    setNewTestModal(false);
  };

  const newTestModalProps = {
    close: closeNewTestModal,
    onOk: createTest,
    title: "New Test: please, enter the introduction",
    mode: "test"
  };
  return !tokenModal && testList ? (
    <div className="w-full p-4 flex flex-col overflow-y-auto relative app-body">
      {testList.map((test: ITest) => {
        const testStatus = test.status || "New";
        return !test.listed && !isAdmin && testStatus === "New" ? null : (
          <div
            className="flex p-1 w-full h-[30px]s items-center justify-between hover:bg-yellow-100 rounded border my-1"
            key={test.id}
            style={{ background: testStatusBackgrounds[testStatus] }}
          >
            <div className="overflow-x-hidden whitespace-nowrap text-ellipsis">
              {test.introduction}
            </div>
            <div className="flex items-center">
              <div className="mr-2">[{test.status}]</div>
              {test.listed || isAdmin ? (
                <Button
                  className="mr-2 rounded"
                  type="primary"
                  onClick={() => navigate(`/tests/${test.id}`)}
                  disabled={
                    !(testStatus === "New" || testStatus === "Unfinished")
                  }
                >
                  Run Test
                </Button>
              ) : null}
              {isAdmin && (
                <>
                  <ListedToggleSwitch
                    test={test}
                    onChange={toggleListed}
                    loading={loading}
                  />

                  <Button
                    className="mr-2 rounded"
                    type="default"
                    onClick={() => navigate(`/edit/${test.id}`)}
                  >
                    Edit Test
                  </Button>
                  <RemoveTestWarning onConfirm={removeTest} test={test} />
                </>
              )}
            </div>
          </div>
        );
      })}
      {isAdmin && <PlusButton onClick={addNewTest} tootlipTitle="New test" />}
      {newTestModal && <NewTestModal {...newTestModalProps} />}
    </div>
  ) : null;
};
