import { Modal, Input, Button, Tooltip } from "antd";
import { useState, FC, FormEvent } from "react";
import { UploadImageBlock } from "./UploadImageBlock";
import { CloseCircleOutlined } from "@ant-design/icons";

interface INewModalProps {
  close: () => void;
  // eslint-disable-next-line
  onOk: (arg: string, image?: string) => void;
  title: string;
  mode: string;
}

export const NewTestModal: FC<INewModalProps> = ({
  close,
  onOk,
  title,
  mode
}: INewModalProps) => {
  const [inputValue, setInputValue] = useState("");
  const [image, setImage] = useState<any>(() => "");

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  const handleOk = () => {
    if (mode === "question") {
      onOk(inputValue, image);
    } else onOk(inputValue);
    close();
  };

  const addPic = async (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImage(reader.result);
    };

    reader.onerror = function () {
      setImage(null);
    };
  };

  return (
    <Modal
      title={title}
      open
      onOk={handleOk}
      closable
      onCancel={close}
      footer={[
        <Button type="default" onClick={close} key="cancelButton">
          Cancel
        </Button>,
        <Button
          type="primary"
          onClick={handleOk}
          disabled={!inputValue}
          key="createButton"
        >
          Create
        </Button>
      ]}
    >
      <Input
        onChange={handleChange}
        value={inputValue}
        onPressEnter={handleOk}
      />
      {mode === "question" && <UploadImageBlock img={image} addPic={addPic} />}
      {mode === "question" && image ? (
        <Tooltip title="Remove image">
          <div
            onClick={() => {
              setImage("");
            }}
          >
            <CloseCircleOutlined className="text-red-500 text-2xl cursor-pointer" />
          </div>
        </Tooltip>
      ) : null}
    </Modal>
  );
};
