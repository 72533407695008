import { Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation";
import "./styles/App.scss";
import { TestList } from "./pages/TestList";
import { TestUnit } from "./pages/TestUnit";
import { Results } from "./pages/admin/Results";
import { TestEdit } from "./pages/admin/TestEdit";
import { useContext, useEffect } from "react";
import { MainContext } from "./context/Main";
import { LoginModal } from "./components/LoginModal";
import { Alert } from "antd";

function App() {
  const { checkToken, tokenModal, error } = useContext(MainContext);

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <>
      <Navigation />{" "}
      {error && <Alert type="error" message={error} style={{ margin: 20 }} />}
      <Routes>
        <Route path="/" element={<TestList />} />
        <Route path="/tests/:id" element={<TestUnit />} />
        <Route path="/results" element={<Results />} />
        <Route path="/edit/:id" element={<TestEdit />} />
      </Routes>
      {tokenModal ? <LoginModal /> : null}
    </>
  );
}

export default App;
