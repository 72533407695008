// const { Magic } = require("magic-sdk");
import { Magic } from "magic-sdk";

const magic = new Magic(process.env.REACT_APP_PUBLIC_KEY!);
let timer: any = null;

export default async function login(
  email: string,
  appLogin: any,
  setLoginLoading: any
) {
  localStorage.clear();
  try {
    await magic.auth.loginWithMagicLink({
      email
    });
    timer = setInterval(
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getToken,
      1000,
      appLogin,
      setLoginLoading
    );
  } catch (error) {
    console.error("loginWithMagicLink ERROR: ", error);
    setLoginLoading(false);
  }
}

async function writeToLocal(did: string) {
  localStorage.setItem(
    "user",
    JSON.stringify({
      did,
      expiry: new Date().getTime() + 1000 * 7200 * 3
    })
  );
}

async function getToken(appLogin: any, setLoginLoading: any) {
  const answer = await magic.user.getMetadata();
  const { issuer } = answer;
  clearInterval(timer);
  await writeToLocal(issuer!);
  appLogin(issuer);
  setLoginLoading(false);
}

export const logout = async () => {
  await magic.user.logout();
};
