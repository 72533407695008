import * as t from "./actionTypes";
import {
  ITest,
  IState,
  Action,
  ISetQuestionPayload,
  ITestContent,
  IQuestion
} from "./interfaces";

const handlers: any = {
  [t.SET_IS_LOGGED_IN]: (state: IState, { payload }: { payload: boolean }) => {
    return {
      ...state,
      isLoggedIn: payload,
      tokenModal: !payload,
      idAdmin: payload ? state.isAdmin : false,
      loading: false
    };
  },

  [t.SET_LOADING]: (state: IState, { payload }: { payload: boolean }) => ({
    ...state,
    loading: payload,
    isLoggedIn: true,
    error: null
  }),

  [t.SET_IS_ADMIN]: (state: IState, { payload }: { payload: boolean }) => ({
    ...state,
    isAdmin: payload,
    loading: false
  }),

  [t.SET_TEST_LIST]: (state: IState, { payload }: { payload: ITest[] }) => ({
    ...state,
    testList: payload.map(el => ({
      id: el.id,
      introduction: el.introduction,
      status: el.TestResults?.[0]?.status || el.status || "New",
      listed: el.listed
    })),
    tokenModal: false,
    loading: false
  }),

  [t.SET_QUESTION_LIST]: (
    state: IState,
    { payload }: { payload: ISetQuestionPayload }
  ) => ({
    ...state,
    questionList: payload.list,
    introduction: payload.introduction || state.introduction,
    loading: false
  }),

  [t.CLEAN_EDIT_SCREEN]: (state: IState) => ({
    ...state,
    questionList: [],
    introduction: ""
  }),

  [t.SET_TEST_CONTENT]: (
    state: IState,
    { payload }: { payload: ITestContent }
  ) => ({
    ...state,
    ...payload,
    loading: false
  }),

  [t.SET_CURRENT_QUESTION]: (
    state: IState,
    { payload }: { payload: IQuestion }
  ) => ({
    ...state,
    currentQuestion: payload,
    questionIndex: state.questionIndex! + 1,
    loading: false
  }),

  [t.FINISH_TEST]: (state: IState, { payload }: { payload: any }) => ({
    ...state,
    introduction: "",
    questions: null,
    resultId: null,
    userId: null,
    questionIndex: null,
    currentQuestion: null,
    loading: false,
    answerCounter: 0,
    status: null,
    testList:
      state.testList && state.testList.length
        ? state.testList.map((el: any) =>
            el.id === payload ? { ...el, status: "Pending review" } : el
          )
        : state.testList
  }),

  [t.SET_RESULTS]: (state: IState, { payload }: { payload: any }) => {
    return { ...state, resultTableData: payload, loading: false };
  },

  [t.SET_ERROR]: (state: IState, { payload }: { payload: string }) => {
    return { ...state, error: payload, loading: false };
  },

  DEFAULT: (state: IState) => state
};

export const appReducer = (state: IState, action: Action) => {
  const handler = handlers[action.type] || handlers.DEFAULT;
  return handler(state, action);
};
