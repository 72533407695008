import { Upload } from "antd";
import { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";

interface IUploadImageProps {
  img?: string;
  // eslint-disable-next-line
  addPic: (file: any) => void;
}

export const UploadImageBlock = ({ img, addPic }: IUploadImageProps) => {
  const [imageValid, setImageValid] = useState(() => false);
  useEffect(() => {
    const image: any = document.createElement("img");
    image.src = img;
    image.onload = () => setImageValid(() => true);
    image.onerror = () => setImageValid(() => false);
    image.remove();
  }, [img]);
  return (
    <Upload
      accept="image/png image/jpeg"
      customRequest={data => addPic(data.file)}
      showUploadList={false}
      beforeUpload={(): any => null}
    >
      {imageValid ? (
        <div className="mt-2" style={{ borderRadius: 10 }}>
          <img
            src={img}
            alt=""
            className="max-h-96"
            style={{ borderRadius: 10 }}
          />
        </div>
      ) : (
        <div className="plus-icon flex items-center mt-2">
          <PlusCircleOutlined className="text-gray-500 text-xl cursor-pointer mr-1 leading-3" />
          <div>Add Image</div>
        </div>
      )}
    </Upload>
  );
};
