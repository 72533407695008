import { useEffect, useContext, useRef, useState, useCallback } from "react";
import { MainContext } from "../../context/Main";
import { AgGridReact } from "ag-grid-react";
import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";
import "ag-grid-enterprise";
import { notification } from "antd";
import { debounce } from "throttle-debounce";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { SparklinesModule } from "@ag-grid-enterprise/sparklines";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { testStatusBackgrounds } from "../../context/constants";

const getRowStyle = (params: any): any => {
  const status = params?.data?.status;
  const backgroundColor = testStatusBackgrounds[status];
  if (backgroundColor) return { backgroundColor };
  return {};
};

export const Results = () => {
  const { resultTableData, getResults, setAnswerRating, updateNotes } =
    useContext(MainContext);
  const [modules] = useState<any>(() => [
    ClientSideRowModelModule,
    CsvExportModule,
    MasterDetailModule,
    FiltersToolPanelModule,
    ColumnsToolPanelModule,
    ClipboardModule,
    SparklinesModule,
    MenuModule,
    RowGroupingModule,
    SetFilterModule,
    MultiFilterModule
  ]);
  useEffect(() => {
    getResults();
  }, []);
  const gridRef = useRef<any>();

  const valueParser = (e: any) => {
    const {
      column: { colId },
      newValue,
      oldValue,
      data,
      node
    } = e;

    if (newValue !== oldValue) {
      const { resultId } = data;
      if (colId === "notes") {
        updateNotes(resultId, newValue);
        return newValue;
      }
      if (newValue && !newValue.match(/^[0-9]+$/)) {
        notification.error({
          duration: 6,
          message: "NUMBERS ONLY!"
        });
        return oldValue;
      }
      const answerId = data[`answerId${colId.replace("rating", "")}`];
      if (answerId) {
        const rating = newValue || "0";
        setAnswerRating(answerId, rating);
        let summRating = 0;
        const { questionCount } = data;
        for (let i = 0; i < questionCount; i++) {
          const currentAnswer = data[`answerId${i}`];
          if (currentAnswer) {
            if (currentAnswer === answerId) {
              const ratingNum = parseInt(newValue) || 0;
              summRating += ratingNum;
            } else {
              const ratingNum = parseInt(data[`rating${i}`]) || 0;
              summRating += ratingNum;
            }
          }
        }
        node.setDataValue(
          "averageRating",
          Math.round((summRating / data.questionCount) * 10) / 10
        );
        return newValue;
      } else
        notification.error({
          duration: 6,
          message: "Failed: EMPTY ANSWER!",
          description: `Rating for answer #${colId.replace(
            "rating",
            ""
          )} has not been saved since there is no related answer yet`
        });
    }
    return oldValue;
  };

  const onGridSizeChanged = debounce(300, (api: any) => {
    if (api.direction !== "vertical") {
      const columns = api.columnApi.getColumns();
      columns.shift();
      setTimeout(() => api.columnApi.autoSizeColumns(columns), 200);
    }
  });

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const result: (string | MenuItemDef)[] = [
        {
          name: "Export CSV",
          action: () => {
            params.api.exportDataAsCsv({
              allColumns: true,
              suppressQuotes: true,
              processCellCallback: params => {
                const { value } = params;
                if (value) return value.toString().replaceAll("\n", "");
                return value;
              }
            });
          }
        }
      ];
      return result;
    },
    []
  );

  return (
    <div className="w-full p-4 flex flex-col overflow-y-auto relative app-body ag-theme-balham">
      {resultTableData && (
        <AgGridReact
          ref={gridRef}
          rowData={resultTableData.rowData}
          modules={modules}
          columnDefs={resultTableData.columnDefs}
          suppressContextMenu={false}
          animateRows
          // onGridReady={onGridReady}
          defaultColDef={{
            filter: true,
            resizable: true,
            sortable: true,
            valueParser
          }}
          suppressExcelExport={true}
          suppressCsvExport={false}
          onGridSizeChanged={onGridSizeChanged}
          onBodyScroll={onGridSizeChanged}
          allowContextMenuWithControlKey={true}
          getContextMenuItems={getContextMenuItems}
          rowSelection="single"
          getRowStyle={getRowStyle}
        />
      )}
    </div>
  );
};
