import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/Main";
import { useParams } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { IQuestion } from "../../context/interfaces";
import { Button, Alert, Popconfirm } from "antd";
import { PlusButton } from "../../components/PlusButton";
import { NewTestModal } from "../../components/NewTestModal";
import { isEmpty } from "lodash";
import { EditItemModal } from "../../components/EditItemModal";

export const TestEdit = () => {
  const params = useParams();
  const {
    getTestWithQuestionsByTestId,
    questionList,
    introduction,
    isAdmin,
    removeQuestion,
    createQuestion,
    editQuestion,
    editTest
  } = useContext(MainContext);
  const [newQuestionModal, setNewQuestionModal] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState<any>({});
  const [testEditing, setTestEditing] = useState(false);

  useEffect(() => {
    const id = params?.id;
    if (id) {
      getTestWithQuestionsByTestId(id);
    }
  }, []);

  const createNewQuestion = (body: string, image?: string) => {
    const model = { body, testId: params?.id, ImagesBase64: image };
    createQuestion(model);
  };

  const addNewQuestion = () => {
    setNewQuestionModal(true);
  };

  const closeNewQuestionModal = () => {
    setNewQuestionModal(false);
  };

  const newQuestionModalProps = {
    close: closeNewQuestionModal,
    onOk: createNewQuestion,
    title: "Enter new question",
    mode: "question"
  };

  const cancelEditQuestion = () => {
    setEditedQuestion({});
  };

  const confirmEditQuestion = (newBody: string, img?: string) => {
    const model = { ...editedQuestion, body: newBody, ImagesBase64: img };
    editQuestion(model);
    setEditedQuestion({});
  };

  const editQuestionModalProps = {
    close: cancelEditQuestion,
    onOk: confirmEditQuestion,
    mode: "question",
    title: "Edit Question",
    initialValue: editedQuestion.body,
    img: editedQuestion.ImagesBase64
  };

  const editQuestionHandler = (question: IQuestion) => {
    setEditedQuestion(() => ({ ...question }));
  };

  const editTestHandler = () => {
    setTestEditing(true);
  };

  const closeTestEditing = () => {
    setTestEditing(false);
  };

  const confirmEditTest = (newIntroduction: string) => {
    const model = { id: params.id, introduction: newIntroduction };
    editTest(model);
    closeTestEditing();
  };

  const editTestModalProps = {
    close: closeTestEditing,
    onOk: confirmEditTest,
    title: "Edit Test Introduction",
    initialValue: introduction,
    mode: "test"
  };

  return isAdmin ? (
    <>
      <div className="w-full p-4 flex flex-col overflow-y-auto relative app-body">
        <div className="w-full px-3 py-1 bg-green-300 justify-center flex items-center font-semibold text-lg">
          {introduction}
          <div
            className="ml-4 cursor-pointer inline-block"
            onClick={() => editTestHandler()}
            style={{ lineHeight: "12px" }}
          >
            <EditOutlined
              className="text-lg text-sky-700"
              style={{ lineHeight: "12px" }}
            />
          </div>
        </div>
        {questionList.map((question: IQuestion) => (
          <div
            className="flex p-1 w-full h-[30px]s items-center justify-between hover:bg-green-100 rounded border my-1"
            key={question.id}
          >
            <div className="overflow-x-hidden whitespace-nowrap text-ellipsis">
              {question.body}
            </div>
            <div className="flex items-center">
              <Button
                className="mr-2 rounded"
                type="default"
                onClick={() => editQuestionHandler(question)}
              >
                Edit Question
              </Button>
              <Popconfirm
                title="Are you sure you want to remote this question?"
                onConfirm={() => removeQuestion(question.id)}
              >
                <Button className="mr-2 rounded" type="default" danger>
                  Remove Question
                </Button>
              </Popconfirm>
            </div>
          </div>
        ))}
        {isAdmin && (
          <PlusButton onClick={addNewQuestion} tootlipTitle="New question" />
        )}
        {newQuestionModal && <NewTestModal {...newQuestionModalProps} />}
        {isEmpty(editedQuestion) ? null : (
          <EditItemModal {...editQuestionModalProps} />
        )}
        {testEditing && <EditItemModal {...editTestModalProps} />}
      </div>
    </>
  ) : (
    <Alert type="error" message="You do not have permissions to be here" />
  );
};
