import { Switch } from "antd";
import { ITest } from "../context/interfaces";

interface IListedSwitchProps {
  test: ITest;
  loading: boolean;
  // eslint-disable-next-line
  onChange: (checked: boolean, id: string) => void;
}

export const ListedToggleSwitch = ({
  test,
  loading,
  onChange
}: IListedSwitchProps) => {
  return (
    <Switch
      checkedChildren="Listed"
      unCheckedChildren="Draft"
      className="listed-toggler"
      checked={test.listed}
      onChange={checked => onChange(checked, test.id)}
      loading={loading}
    />
  );
};
