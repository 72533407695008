import { Tooltip, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IPlusButtonProps } from "../context/interfaces";

export const PlusButton = ({ onClick, tootlipTitle }: IPlusButtonProps) => {
  return (
    <Tooltip title={tootlipTitle} placement="right">
      <Button
        type="default"
        className="absolute w-[40px] h-[40px] flex items-center justify-center rounded-3xl bottom-6 right-6 plus-button"
        onClick={onClick}
      >
        <PlusCircleOutlined className="text-2xl text-gray-500" />
      </Button>
    </Tooltip>
  );
};
