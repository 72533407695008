import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { MainState } from "./context/Main";
import { ApolloProvider } from "@apollo/client";
import { client } from "./context/graphqlStuff";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <MainState>
        <App />
      </MainState>
    </BrowserRouter>
  </ApolloProvider>
);
