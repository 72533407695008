import { ICellRendererParams } from "ag-grid-community";
import moment from "moment";
import {
  QuestionAnswerGridRenderer,
  StatusRenderer
} from "../components/GridComponents";

interface IRow {
  value: number | string;
  type: "age" | "gender" | "mood";
}

const cellRendererSelector = (params: ICellRendererParams<IRow>) => {
  return params?.value?.length > 14
    ? { component: QuestionAnswerGridRenderer }
    : null;
};

export const fetchResults = (results: any[]) => {
  let maxQuestionsCount = 0;
  const rowData = results.map(res => {
    const row: any = {
      user: res.Candidate?.id,
      introduction: res.Test?.introduction,
      status: res.status,
      resultId: res.id,
      notes: res.notes,
      createdAt: res.created_at
    };
    const questions = res.Test?.Questions;
    if (questions.length > maxQuestionsCount)
      maxQuestionsCount = questions.length;

    if (questions) {
      let summRating = 0;
      // row.status = res.status;
      // row.resultId = res.id;
      // row.notes = res.notes;
      questions.forEach((q: any, i: number) => {
        row[`question${i}`] = q.body;

        row[`answer${i}`] = res.Answers?.[i]?.body;
        const rating = res.Answers?.[i]?.rating;
        const ratingNum = parseInt(rating) || 0;
        summRating += ratingNum;

        row[`rating${i}`] = rating;
        row[`answerId${i}`] = res.Answers?.[i]?.id;
        row[`answerDuration${i}`] = res.Answers?.[i]?.created_at
          ? i
            ? moment(res.Answers?.[i]?.created_at).diff(
                moment(res.Answers?.[i - 1]?.created_at),
                "seconds"
              )
            : moment(res.Answers?.[i]?.created_at).diff(
                moment(res.created_at),
                "seconds"
              )
          : "";
      });
      row.questionCount = questions.length;
      row.averageRating =
        Math.round((summRating / row.questionCount) * 10) / 10;
    }
    return row;
  });
  const columnDefs: any[] = [
    { headerName: "Candidate", field: "user", width: 350 },
    {
      headerName: "Created",
      field: "createdAt",
      width: 125,
      valueFormatter: ({ value }: any) =>
        moment(value).format("DD-MM-YY, hh:mm")
    },
    { headerName: "Test", field: "introduction" },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: StatusRenderer
    },
    {
      headerName: "Notes",
      field: "notes",
      editable: true
      // cellRenderer: StatusRenderer
    },
    {
      headerName: "Avg. Rating",
      field: "averageRating"
    }
  ];

  for (let i = 0; i < maxQuestionsCount; i++) {
    columnDefs.push({
      headerName: "Answer" + (i + 1),
      children: [
        {
          headerName: "Q",
          field: "question" + i,
          width: 40,
          cellRendererSelector
        },
        {
          headerName: "A",
          field: "answer" + i,
          width: 40,
          cellRendererSelector
        },
        {
          headerName: "R",
          field: "rating" + i,
          editable: true,
          width: 90
        },
        { headerName: "D (sec)", field: "answerDuration" + i, width: 90 }
      ]
    });
  }

  return { columnDefs, rowData };
};
