import { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MainContext } from "../context/Main";
import {
  LoadingOutlined,
  CopyOutlined,
  CheckOutlined
} from "@ant-design/icons";
import { Popconfirm, Button, Tooltip } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const Navigation = () => {
  const [pathname, setPathname] = useState("/");
  const { isAdmin, loading, logout, isLoggedIn } = useContext(MainContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [topDid, setTopDid] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (location?.pathname !== pathname) setPathname(location?.pathname);
  }, [location, setPathname]);

  useEffect(() => {
    if (isLoggedIn && !topDid) {
      try {
        const user = localStorage.getItem("user");
        const token = user ? JSON.parse(user)?.did : null;
        if (token) setTopDid(token);
      } catch (error) {
        console.error("Navigation Token Error: ", error);
      }
    } else if (topDid && !isLoggedIn) {
      setTopDid("");
    }
  }, [isLoggedIn]);

  return (
    <nav className="h-[50px] flex items-center justify-between px-5 bg-gray-500 text-white navigation relative">
      {loading && <LoadingOutlined />}
      {topDid ? (
        <div className="h-100 flex items-center ml-2">
          <span className="text-gray-300">Token:&nbsp;</span>
          <span className="text-lg">{topDid}</span>
          <CopyToClipboard text={topDid} onCopy={() => setIsCopied(true)}>
            <Button className="ml-2 px-1 text-white rounded-lg pt-1 text-lg leading-3">
              <Tooltip title="Copy to clipboard">
                {isCopied ? (
                  <CheckOutlined style={{ color: "#00ff00" }} />
                ) : (
                  <CopyOutlined />
                )}
              </Tooltip>
            </Button>
          </CopyToClipboard>
        </div>
      ) : (
        <span />
      )}
      <div>
        {pathname !== "/" && (
          <Link to="/" className="mr-3 inline-link">
            Back to list
          </Link>
        )}
        {isAdmin && pathname !== "/results" ? (
          <Link to="/results" className="mr-3 inline-link">
            Results
          </Link>
        ) : null}
        <Popconfirm
          onConfirm={() => {
            logout();
            navigate("/");
          }}
          title="Log out?"
          placement="leftBottom"
        >
          <span className="inline-link underline">Logout</span>
        </Popconfirm>
      </div>
    </nav>
  );
};
