import { Button, Popover, Input } from "antd";
import { useState, useEffect, ChangeEvent } from "react";

export const RemoveTestWarning = ({ onConfirm, test }: any) => {
  const [popupVisible, setPopupVisible] = useState(false);

  const onOpenChange = (open: boolean) => {
    if (open) setPopupVisible(true);
  };

  return (
    <Popover
      title={`Remove test ${test.introduction}`}
      content={
        <WarningContent
          onConfirm={onConfirm}
          test={test}
          setPopupVisible={setPopupVisible}
        />
      }
      destroyTooltipOnHide
      trigger="click"
      open={popupVisible}
      onOpenChange={onOpenChange}
    >
      <Button className="mr-2 rounded" type="default" danger>
        Remove Test
      </Button>
    </Popover>
  );
};

const WarningContent = ({ onConfirm, test, setPopupVisible }: any) => {
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (!value || value.match(/^[0-9]+$/)) {
      setSum(value);
      if (Number(value) === numbers.sum) {
        setDeleteDisabled(false);
      } else if (!deleteDisabled) setDeleteDisabled(true);
    }
  };

  const [sum, setSum] = useState("");
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const [numbers, setNumbers] = useState<any>({});

  useEffect(() => {
    const number1 = Math.ceil(Math.random() * 1000);
    const number2 = Math.ceil(Math.random() * 1000);
    const sum = number1 + number2;
    setNumbers({ number1, number2, sum });
  }, []);

  return (
    <div>
      <p>Are you sure you want to totally remote this test </p>
      <p>(including all related resulst, questions and answers)?</p>
      <p>
        {numbers.number1} + {numbers.number2!}
      </p>
      <Input size="small" value={sum} onChange={onChange}></Input>
      <Button
        size="small"
        type="primary"
        danger
        onClick={() => onConfirm(test.id)}
        disabled={deleteDisabled}
        className="mr-2 mt-2"
      >
        Delete test
      </Button>
      <Button
        size="small"
        onClick={() => setPopupVisible(false)}
        className="mt-2"
      >
        Cancel
      </Button>
    </div>
  );
};
