import {
  ApolloClient,
  InMemoryCache,
  gql,
  createHttpLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { URL } from "../constants";

const httpLink = createHttpLink({
  uri: URL
});

const authLink = setContext((_, { headers }) => {
  const user = localStorage.getItem("user");
  const token = user ? JSON.parse(user)?.did : null;
  return {
    headers: {
      ...headers,
      "x-hasura-user-id": token || ""
    }
  };
});

const adminLAuthLink = setContext((_, { headers }) => {
  const user = localStorage.getItem("user");
  const token = user ? JSON.parse(user)?.did : null;
  return {
    headers: {
      ...headers,
      "x-hasura-admin-secret": token || ""
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export const adminClient = new ApolloClient({
  link: adminLAuthLink.concat(httpLink),
  cache: new InMemoryCache()
});

export const GET_TEST_LIST = gql`
  query MyQuery($userId: String) {
    Test {
      id
      introduction
      listed
      TestResults(where: { userId: { _eq: $userId } }) {
        status
      }
    }
  }
`;

export const CREATE_NEW_TEST = gql`
  mutation MyMutation($introduction: String, $userId: String) {
    insert_Test(objects: { introduction: $introduction }) {
      returning {
        id
        introduction
        listed
      }
    }
  }
`;

export const GET_QUESTIONS_BY_TEST_ID = gql`
  query MyQuery($id: uuid) {
    Question(where: { testId: { _eq: $id } }, order_by: { created_at: asc }) {
      id
      body
      ImagesBase64
    }
  }
`;

export const GET_TEST_BY_ID = gql`
  query MyQuery($id: uuid) {
    Test(where: { id: { _eq: $id } }) {
      introduction
      listed
    }
  }
`;

export const CREATE_NEW_QUESTION = gql`
  mutation MyMutation($body: String, $testId: uuid, $ImagesBase64: String) {
    insert_Question(
      objects: { body: $body, testId: $testId, ImagesBase64: $ImagesBase64 }
    ) {
      returning {
        testId
        id
        body
        ImagesBase64
      }
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation MyMutation($id: uuid) {
    delete_Question(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const EDIT_QUESTION = gql`
  mutation MyMutation($body: String, $id: uuid, $ImagesBase64: String) {
    update_Question(
      _set: { body: $body, ImagesBase64: $ImagesBase64 }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        testId
        body
        ImagesBase64
      }
    }
  }
`;

export const EDIT_TEST = gql`
  mutation MyMutation($id: uuid, $introduction: String, $userId: String) {
    update_Test(
      where: { id: { _eq: $id } }
      _set: { introduction: $introduction }
    ) {
      returning {
        introduction
        id
        TestResults(where: { userId: { _eq: $userId } }) {
          status
        }
      }
    }
  }
`;

export const GET_QUESTIONS_IDS_BY_TEST_ID = gql`
  query MyQuery($id: uuid) {
    Question(where: { testId: { _eq: $id } }, order_by: { created_at: asc }) {
      id
    }
  }
`;

export const GET_CANDIDATE = gql`
  query MyQuery($id: String!) {
    Candidate_by_pk(id: $id) {
      id
    }
  }
`;

export const CREATE_CANDIDATE = gql`
  mutation MyMutation($id: String) {
    insert_Candidate(objects: { id: $id }) {
      returning {
        id
      }
    }
  }
`;

export const GET_RESULT_BY_TEST_ID = gql`
  query MyQuery($id: uuid) {
    TestResult(where: { testId: { _eq: $id } }) {
      id
      userId
      status
    }
  }
`;

export const CREATE_NEW_RESULT = gql`
  mutation MyMutation($testId: uuid, $userId: String) {
    insert_TestResult(objects: { testId: $testId, userId: $userId }) {
      returning {
        id
        testId
        userId
      }
    }
  }
`;

export const GET_ANSWERS_BY_RESULT_ID = gql`
  query MyQuery($resultId: uuid) {
    Answer(where: { resultId: { _eq: $resultId } }) {
      id
    }
  }
`;

export const GET_QUESTION_BY_ID = gql`
  query MyQuery($id: uuid!) {
    Question_by_pk(id: $id) {
      ImagesBase64
      body
    }
  }
`;

export const CREATE_NEW_ANSWER = gql`
  mutation MyMutation($body: String, $resultId: uuid, $userId: String) {
    insert_Answer_one(
      object: { body: $body, resultId: $resultId, userId: $userId }
    ) {
      id
    }
  }
`;

export const GET_RESULTS_LIST = gql`
  query MyQuery {
    TestResult(order_by: { created_at: desc }) {
      Answers(order_by: { created_at: asc }) {
        body
        rating
        created_at
        id
      }
      Candidate {
        id
      }
      Test {
        introduction
        id
        Questions(order_by: { created_at: asc }) {
          body
        }
      }
      created_at
      id
      status
      notes
    }
  }
`;

export const UPDATE_ANSWERS_RATING = gql`
  mutation MyMutation($id: uuid!, $rating: String) {
    update_Answer_by_pk(pk_columns: { id: $id }, _set: { rating: $rating }) {
      id
    }
  }
`;

export const UPDATE_NOTES = gql`
  mutation MyMutation($resultId: uuid!, $notes: String) {
    update_TestResult_by_pk(
      pk_columns: { id: $resultId }
      _set: { notes: $notes }
    ) {
      created_at
      id
      status
      notes
    }
  }
`;

export const DELETE_ANSWERS_BY_TEST_ID = gql`
  mutation MyMutation($id: uuid!) {
    delete_Answer(where: { TestResult: { Test: { id: { _eq: $id } } } }) {
      returning {
        id
      }
    }
    delete_TestResult(where: { Test: { id: { _eq: $id } } }) {
      returning {
        id
      }
    }
    delete_Question(where: { Test: { id: { _eq: $id } } }) {
      returning {
        id
      }
    }
    delete_Test_by_pk(id: $id) {
      introduction
    }
  }
`;

export const SET_RESULT_STATUS_ADMIN = gql`
  mutation MyMutation($resultId: uuid!, $status: String) {
    update_TestResult_by_pk(
      pk_columns: { id: $resultId }
      _set: { status: $status }
    ) {
      created_at
      id
      status
      notes
    }
  }
`;
export const SET_RESULT_STATUS = gql`
  mutation MyMutation($resultId: uuid!, $status: String) {
    update_TestResult_by_pk(
      pk_columns: { id: $resultId }
      _set: { status: $status }
    ) {
      created_at
      id
      status
    }
  }
`;

export const TOGGLE_LISTED = gql`
  mutation MyMutation($testId: uuid!, $listed: Boolean) {
    update_Test_by_pk(pk_columns: { id: $testId }, _set: { listed: $listed }) {
      id
      listed
      introduction
    }
  }
`;
